export const rulesStor = [
  {
    id: 1,
    name: "Правила чата",
    items: [
      {
        id: 1,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 2,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 3,
        name: "Запрещено всё (Блокировка чата навсегда)"
      }
    ]
  },
  {
    id: 2,
    name: "Правила чата",
    items: [
      {
        id: 1,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 2,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 3,
        name: "Запрещено всё (Блокировка чата навсегда)"
      }
    ]
  },
  {
    id: 3,
    name: "Правила чата",
    items: [
      {
        id: 1,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 2,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 3,
        name: "Запрещено всё (Блокировка чата навсегда)"
      }
    ]
  },
  {
    id: 4,
    name: "Правила чата",
    items: [
      {
        id: 1,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 2,
        name: "Запрещено всё (Блокировка чата навсегда)"
      },
      {
        id: 3,
        name: "Запрещено всё (Блокировка чата навсегда)"
      }
    ]
  },
];
import EndCity from "../../images/end-city.svg";

const Decoration = () => {
  return (
    <section className="decoration">
      <img src={EndCity} alt="End-City"/>
    </section>
  )
};

export default Decoration;